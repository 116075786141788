import { Networks } from "../../constants/blockchain";
import { LPBond, CustomLPBond } from "./lp-bond";
import { StableBond, CustomBond } from "./stable-bond";

import UsdcIcon from "../../assets/tokens/USDC.svg";
import AvaxIcon from "../../assets/tokens/AVAX.svg";
import MimHentaiIcon from "../../assets/tokens/hentai-mim.png";
import DaiHentaiIcon from "../../assets/tokens/hentai-dai.png";
import AvaxTimeIcon from "../../assets/tokens/TIME-AVAX.svg";
import DAIIcon from "../../assets/tokens/DAI.e.svg"

import { StableBondContract, LpBondContract, WavaxBondContract, StableReserveContract, LpReserveContract, JoeLpReserveContract } from "../../abi";

export const usdc = new StableBond({
    name: "usdc",
    displayName: "USDC",
    bondToken: "USDC",
    bondIconSvg: UsdcIcon,
    bondContractABI: StableBondContract,
    reserveContractAbi: StableReserveContract,
    networkAddrs: {
        [Networks.AVAX]: {
            bondAddress: "0x2D2909f6181dc2c0c2Ab7372E2F01c4e75B85f83",
            reserveAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
        },
    },
});

export const dai = new StableBond({
    name: "dai",
    displayName: "DAI",
    bondToken: "DAI",
    bondIconSvg: DAIIcon,
    bondContractABI: StableBondContract,
    reserveContractAbi: StableReserveContract,
    networkAddrs: {
        [Networks.AVAX]: {
            bondAddress: "0x793393a2a0E2dCeAba687c139F657BdC9259335D",
            reserveAddress: "0xd586e7f844cea2f87f50152665bcbc2c279d8d70",
        },
    },
});

export const wavax = new CustomBond({
    name: "wavax",
    displayName: "wAVAX",
    bondToken: "AVAX",
    bondIconSvg: AvaxIcon,
    bondContractABI: WavaxBondContract,
    reserveContractAbi: StableReserveContract,
    networkAddrs: {
        [Networks.AVAX]: {
            bondAddress: "0xE02B1AA2c4BE73093BE79d763fdFFC0E3cf67318",
            reserveAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
        },
    },
    // tokensInStrategy: "756916000000000000000000",
});

export const mimHentai = new LPBond({
    name: "usdc_hentai_lp",
    displayName: "Hentai-USDC LP",
    bondToken: "USDC",
    bondIconSvg: MimHentaiIcon,
    bondContractABI: LpBondContract,
    reserveContractAbi: LpReserveContract,
    networkAddrs: {
        [Networks.AVAX]: {
            bondAddress: "0xC59c97065873a433431A880e5a30888605323D87",
            reserveAddress: "0xD99906974589EeC715ac78297F8DFbD8525C7C65",
        },
    },
    lpUrl: "https://traderjoexyz.com/avalanche/pool/v1/create/0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E/0x16EC875dA4B18f53F0825a4Cf21EE6287d50976D",
});

export const mimswHentai = new LPBond({
    name: "usdc_wshentai_lp",
    displayName: "wsHentai-USDC LP",
    bondToken: "USDC",
    bondIconSvg: MimHentaiIcon,
    bondContractABI: LpBondContract,
    reserveContractAbi: LpReserveContract,
    networkAddrs: {
        [Networks.AVAX]: {
            bondAddress: "0xC59c97065873a433431A880e5a30888605323D87",
            reserveAddress: "0x5008Bc93E5b3FF12F5C1FaB7aA084E7f290d11ec",
        },
    },
    lpUrl: "https://traderjoexyz.com/avalanche/pool/v1/create/0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E/0x16EC875dA4B18f53F0825a4Cf21EE6287d50976D",
});

export const daiHentai = new LPBond({
    name: "dai_hentai_lp",
    displayName: "Hentai-DAI LP",
    bondToken: "DAI",
    bondIconSvg: DaiHentaiIcon,
    bondContractABI: LpBondContract,
    reserveContractAbi: JoeLpReserveContract,
    networkAddrs: {
        [Networks.AVAX]: {
            bondAddress: "0x0a235951b775b89d3d531757787ce7b605f3afc0",
            reserveAddress: "0x2FB85dc23D5aC0130cEA07EF8736bB091b03117d",
        },
    },
    lpUrl: "https://traderjoexyz.com/#/pool/0xd586E7F844cEa2F87f50152665BCbc2C279D8d70/0x16EC875dA4B18f53F0825a4Cf21EE6287d50976D",
});

export const avaxHentai = new CustomLPBond({
    name: "avax_verse_lp",
    displayName: "VERSE-AVAX LP",
    bondToken: "AVAX",
    bondIconSvg: AvaxTimeIcon,
    bondContractABI: LpBondContract,
    reserveContractAbi: LpReserveContract,
    networkAddrs: {
        [Networks.AVAX]: {
            bondAddress: "0xc26850686ce755FFb8690EA156E5A6cf03DcBDE1",
            reserveAddress: "0xf64e1c5B6E17031f5504481Ac8145F4c3eab4917",
        },
    },
    lpUrl: "https://www.traderjoexyz.com/#/pool/AVAX/0xb54f16fB19478766A268F172C9480f8da1a7c9C3",
});

// export default [mim, wavax, mimTime, avaxTime];
export default [usdc];
