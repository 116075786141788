import { ethers } from "ethers";
import { LpReserveContract, JoeLpReserveContract } from "../abi";
import { mimHentai, mimswHentai } from "../helpers/bond";
import { Networks } from "../constants/blockchain";

export async function getMarketPrice(networkID: Networks, provider: ethers.Signer | ethers.providers.Provider): Promise<number> {
    const mimHentaiAddress = mimHentai.getAddressForReserve(networkID);

    const pairContract = new ethers.Contract(mimHentaiAddress, JoeLpReserveContract, provider);
    const reserves = await pairContract.getReserves();
    const marketPrice = reserves[0] / reserves[1];
    return marketPrice;
}

export async function getWsMarketPrice(networkID: Networks, provider: ethers.Signer | ethers.providers.Provider): Promise<number> {
    const mimwsHentaiAddress = mimswHentai.getAddressForReserve(networkID);

    const pairContract = new ethers.Contract(mimwsHentaiAddress, JoeLpReserveContract, provider);
    const reserves = await pairContract.getReserves();
    const marketPrice = reserves[0] / reserves[1];
    return marketPrice;
}