import HentaiImg from "../assets/tokens/Hentai.png";
import sHentaiImg from "../assets/tokens/sHentai.png";

function toUrl(tokenPath: string): string {
    const host = window.location.origin;
    return `${host}/${tokenPath}`;
}

export function getTokenUrl(name: string) {
    if (name === "hentai") {
        return toUrl(HentaiImg);
    }

    if (name === "shentai") {
        return toUrl(sHentaiImg);
    }

    throw Error(`Token url doesn't support: ${name}`);
}
