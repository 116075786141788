import { Networks } from "./blockchain";

const AVAX_MAINNET = {
    DAO_ADDRESS: "0x5A174129ccD558de4f5D7c3C85717B0615aB0E7d",
    sHentai_ADDRESS: "0xB7a776e7d9650A7E12A6D7e2FC376E403a5c511c",
    Hentai_ADDRESS: "0x16EC875dA4B18f53F0825a4Cf21EE6287d50976D",
    USDC_ADDRESS: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
    STAKING_ADDRESS: "0xCFF155054DC0F293d59428AC163ad975Cb30145A",
    STAKING_HELPER_ADDRESS: "0x28f8220b87504Ea182C5AFB0bb31B8959a824102",
    Hentai_BONDING_CALC_ADDRESS: "0x87e95fae4a12b42c7a148cb77df8d4f93b37fbe3",
    TREASURY_ADDRESS: "0x90bcf3858ED634E1aCCEc1abBc5185a2596CE095",
    ZAPIN_ADDRESS: "0x9ABE63C5A2fBcd54c8bAec3553d326356a530cae", //"0xb98007C04f475022bE681a890512518052CE6104",
    WsHentai_ADDRESS: "0xeE2037E582bf4B49666DE294C3F2D3aAaFb5aeA1", //"0xE6420ee02D2B61Bd8556E5F8a5197983ce51e1f1",
};

export const getAddresses = (networkID: number) => {
    if (networkID === Networks.AVAX) return AVAX_MAINNET;

    throw Error("Network don't support");
};
